<template>
  <div id="mrf-month-list">
    <v-overlay :value="ProgressLoadingFlag">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
    <v-card>
      <v-card-title class="text-h5">
        <h2>{{ PageTitle }}</h2>
      </v-card-title>

      <v-dialog v-model="EditRecordPrompt" persistent max-width="50%">
        <mrf-deadline-edit
          :EditRecordPrompt="EditRecordPrompt"
          :recordData="SelectedData"
          @hideDialog="hideEditRecordPrompt"
          v-if="EditRecordPrompt"
        ></mrf-deadline-edit>
      </v-dialog>

      <v-card-text>
        <v-row wrap>
          <v-col align="center" cols="12" md="12">
            <v-btn
              large
              @click.prevent="refreshPageData"
              color="#a4c639"
              elevation="30"
              shaped
              class="font-size-h6 mr-3 my-3 white--text"
            >
              <v-icon dark>mdi-refresh</v-icon>&nbsp; Refresh
            </v-btn>
          </v-col>
        </v-row>
        <div class="d-flex justify-content-center mb-3" v-if="LoadingFlag">
          <b-spinner variant="primary" type="grow" label="Loading..."></b-spinner>
        </div>
        <v-container class="py-0" v-if="TableData.length == 0">
          <v-row wrap>
            <v-col align="center" cols="12" md="12">
              <h3>No records found.</h3>
            </v-col>
          </v-row>
        </v-container>
        <v-container class="py-0" v-if="TableData.length > 0">
          <v-row wrap>
            <v-col align="center" cols="12" md="12">
              <h3>{{ TableData.length }} records found.</h3>
            </v-col>
          </v-row>
          <v-row wrap>
            <v-col align="center" cols="12" md="12">
              <v-text-field
                v-model="search"
                append-icon="mdi-magnify"
                label="Search records here"
                single-line
                hide-details
              ></v-text-field>
              <br />
              <v-data-table
                class="elevation-1"
                loading-text="Loading... Please wait"
                v-model="selected"
                :search="search"
                :item-key="TableOptions.ItemKey"
                :single-select="TableOptions.SingleSelectFlag"
                :headers="TableColumns"
                :items="TableData"
                :items-per-page="TableOptions.ItemsPerPage"
                :footer-props="TableOptions.FooterProps"
                :hide-default-footer="TableOptions.HideDefaultFooter"
              >
                <template v-slot:item.DeadlineDateTxt="{ item }">
                  <v-chip
                    :color="item.DeadlineDateColor"
                    draggable
                    dark
                    >{{ item.DeadlineDateTxt }}</v-chip
                  >
                </template>
                <template v-slot:item.actions="{ item }">
                  <v-tooltip bottom v-if="item.PreviewFlag == true">
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon
                        icon
                        size="25px"
                        color="blue"
                        @click="previewData(item, e)"
                        v-bind="attrs"
                        v-on="on"
                      >mdi-file-search</v-icon>
                    </template>
                    <span>Preview</span>
                  </v-tooltip>
                  <v-tooltip bottom v-if="item.EditFlag">
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon
                        icon
                        size="25px"
                        color="primary"
                        @click="editData(item, e)"
                        v-bind="attrs"
                        v-on="on"
                      >mdi-file-document-edit</v-icon>
                    </template>
                    <span>Edit</span>
                  </v-tooltip>
                  <v-tooltip bottom v-if="item.DeleteFlag">
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon
                        icon
                        size="25px"
                        color="red"
                        @click="deleteConfirmAlert(item, e)"
                        v-bind="attrs"
                        v-on="on"
                      >mdi-delete</v-icon>
                    </template>
                    <span>Delete</span>
                  </v-tooltip>
                </template>
              </v-data-table>
            </v-col>
          </v-row>
        </v-container>
        <br />
        <br />
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import common from "@/view/Common.vue";
import companyConfig from "@/company_config.json";
import MrfDeadlineEdit from "@/view/pages/erp/mrf-month/MrfDeadlineEdit.vue";
import Swal from "sweetalert2";

export default {
  mixins: [common],
  components: { MrfDeadlineEdit },
  data() {
    return {
      valid1: true,
      valid2: true,
      LoadingFlag: false,
      SearchFlag: false,
      ProgressLoadingFlag: false,
      SpeedDialFlag: false,

      EditRecordPrompt: false,

      TableData: [],
      TableColumns: [],
      TableOptions: [],
      selected: [],
      search: "",
      CurrentYearId: "",

      SelectedData: {},

      PageInfo: {},
      PageTitle: "",
      PageDescription: "",
      JciYearCode: "",
      YearName: ""
    };
  },
  mounted() {},
  watch: {
    PageInfo: function() {
      console.log("watch PageInfo");
      this.LoadingFlag = false;
      var tr = this.PageInfo;
      console.log({ tr });
      var n1 = Object.keys(tr).length;
      console.log({ n1 });
      if (n1 > 0) {
        var flag = tr.PagePermissionFlag;
        console.log("PagePermissionFlag=" + flag);

        if (!flag) {
          this.$router.push("/access-denied");
        }

        this.PageTitle = tr.PageTitle;
        this.PageDescription = tr.PageDescription;

        var CurrentJciYearId = parseInt(tr.CurrentJciYearId);
        console.log({ CurrentJciYearId });
        this.JciYearCode = CurrentJciYearId;

        var YearName = parseInt(tr.CurrentJciYearName);
        console.log({ YearName });
        this.YearName = YearName;

        this.CurrentYearId = CurrentJciYearId;

        var LomId = this.$session.get("LomId");
        LomId = LomId == (null || undefined) ? "" : LomId;
        this.LomCode = LomId;

        this.searchForm();
      }
    }
  },
  methods: {
    refreshPageData() {
      console.log("refreshPageData called");
      this.LoadingFlag = true;
      var url1 = "api/menu-details/show";
      var condition1 = {
        UserInterface: 1,
        Module: "mrf",
        Action: "list_1"
      };
      this.getPageDetails("PageInfo", {}, url1, condition1);
    },
    editData(tr) {
      console.log("editData called");
      var n1 = Object.keys(tr).length;
      console.log("n1=" + n1 + ", tr=" + JSON.stringify(tr));
      if (n1 > 0) {
        this.EditRecordPrompt = true;
        this.SelectedData = tr;
      } else {
        var message = "Kindly select one lom to preview";
        this.toast("error", message);
      }
    },
    hideEditRecordPrompt() {
      console.log("hideEditRecordPrompt called");
      this.EditRecordPrompt = false;
      this.searchForm();
    },
    searchForm() {
      console.log("searchForm is called");

      this.SearchFlag = true;
      this.LoadingFlag = true;
      this.TableData = [];

      var server_url = companyConfig.apiURL;
      var token = this.$session.get("token");
      token = token == (null || undefined) ? 0 : token;
      var add_url = server_url + "api/jci-mrf/lists";
      var upload = {
        UserInterface: 1,
        Year: this.JciYearCode
      };
      console.log("upload=" + JSON.stringify(upload));
      console.log(
        "server_url=" + server_url + ", token=" + token + ", add_url=" + add_url
      );

      const thisIns = this;
      var output = "";
      var records = "";
      var flag = 0;

      this.$http({
        url: add_url,
        method: "POST",
        data: upload,
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token
        },
        onUploadProgress: function(progressEvent) {
          this.uploadPercentage = parseInt(
            Math.round((progressEvent.loaded / progressEvent.total) * 100)
          );
        }.bind(this)
      })
        .then(function(response) {
          console.log("response=" + JSON.stringify(response));

          thisIns.SearchFlag = false;
          thisIns.LoadingFlag = false;

          output = response.data.output;
          flag = response.data.flag;
          output = response.data.output;
          records = response.data.records;
          console.log("output=" + output + ", flag=" + flag);

          var options = records.TableOptions;
          thisIns.TableOptions = options;

          if (flag == 1) {
            thisIns.TableData = records.TableData;
            thisIns.TableColumns = records.TableHeader;
            thisIns.toast("success", output);
          } else {
            thisIns.toast("error", output);
          }
        })
        .catch(function(error) {
          console.log("error=" + error);
          thisIns.SearchFlag = false;
          thisIns.LoadingFlag = false;
        });
    }
  },
  mounted() {},
  beforeMount() {
    this.refreshPageData();
  }
};
</script>
<style lang="scss">
/* This is for documentation purposes and will not be needed in your application */

#mrf-month-list {
  .v-btn--floating {
    position: relative;
  }
  .v-speed-dial--bottom {
    bottom: 12%;
  }
  .v-speed-dial--right {
    right: 5%;
  }
  .v-data-table > .v-data-table__wrapper > table > thead > tr > th {
    text-align: center;
    font-size: 16px;
    font-weight: bold;
  }
  .v-data-table > .v-data-table__wrapper > table > tbody > tr > td {
    font-size: 14px;
  }
  .v-data-table > .v-data-table__wrapper > table > tbody tr:nth-of-type(odd) {
    background-color: #f5f5f5;
  }
}
</style>